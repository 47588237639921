// Here you can add other styles

.login-bg {
  background-image: url("/assets/img/newspaper.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.link-highlight {
  text-decoration: underline;
  text-decoration-color: #b9d8ff;
}

